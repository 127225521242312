import getConfig from 'next/config'
import { ItemListElements } from 'next-seo/lib/jsonld/breadcrumb'

export const generateBreadCrumbs = (query: NodeJS.Dict<string[]>): ItemListElements[] => {
  const { publicRuntimeConfig } = getConfig()
  const { webDomain } = publicRuntimeConfig
  const homePageBreadCrumb: ItemListElements = {
    position: 1,
    name: 'Homepage', //Homepage is default not shown in GSearch UI
    item: `https://${webDomain}/`,
  }

  if (Object.keys(query).length === 0) {
    return [ homePageBreadCrumb ]
  }

  const breadCrumbs = query?.slug?.map((slug, index) => {
    const initialBreadCrumbs = query?.slug?.slice(0, index + 1).join('/')

    return {
      position: index + 2,
      name: slug,
      item: `https://${webDomain}/${initialBreadCrumbs}`
    }
  }) as ItemListElements[]

  breadCrumbs?.unshift(homePageBreadCrumb)

  return breadCrumbs
}
