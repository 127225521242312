/* eslint-disable no-console */
import { GetServerSideProps, InferGetServerSidePropsType } from 'next'
import getConfig from 'next/config'
import absoluteUrl from 'next-absolute-url'
import { useRouter } from 'next/router'
import { BreadcrumbJsonLd } from 'next-seo'
import {
  INITIAL_MAIN_RESOURCE_LIST_COUNT,
  INITIAL_SUB_RESOURCE_LIST_COUNT,
  LOAD_MORE_COUNT,
  REDIS_HOMEPAGE_KEY,
} from '@/constants'
import {
  checkAndGetCarrierListingSectionData,
  getEvents,
  getFullPageContent,
  getFullPageSlugs,
  getPmArticles,
  getThumbnails,
} from '@/fetchers'
import { getEventContent, getEventSlug } from '@/fetchers'
import {
  ErrorPage,
  PageLayout,
  LocalThemeProvider,
} from '@/components'
import Head from '@/components/DocumentHead'
import {
  checkIsErrorPage,
  checkIsPreviewModeRequest,
  getPageSlug,
  capitalize,
  getLatestTopicTagRelatedArticles,
  checkIsEventPageApp,
  sortDatesByClosest,
  modifyEventsData,
  checkIsResourcesPageApp,
  modifyResourcesData,
  resourcesPageSectionListModify,
  sortDatesBy,
} from '@/utils'
import { CategoryTypeKeys } from '@/interfaces/category'
import { ILanguageFields } from '@/interfaces/head'
import { DOMAIN_LOCALES } from '@/interfaces/locales'
import ContentTypeKeys from '@/interfaces/contentType'
import { DEVELOPMENT_HOST } from '@/constants/app.constants'
import {
  EventsSectionType,
  EventType,
  IEvent,
} from '@/interfaces/eventsSection'
import { getPageTheme } from '@/utils/theme.utils'
import { generateBreadCrumbs } from '@/utils/breadcrumb.utils'
import { ArticleProps, PmArticleBooleanType } from '@/interfaces/article'

const { serverRuntimeConfig } = getConfig()

const FullPage: React.FC<
  InferGetServerSidePropsType<typeof getServerSideProps>
> = ({
  data,
  languageData,
  status,
  currentDomain,
  isRedis,
}) => {
  // eslint-disable-next-line no-console
  if (isRedis) console.log('v.2.1')

  const router = useRouter()

  if (status) return <ErrorPage code={status} domain={currentDomain} />
  if (!data) return <ErrorPage code='404' domain={currentDomain} />

  if (data.events) {
    const events = modifyEventsData(data.events)

    data.sectionList = data?.sectionList?.map((section: any) => {
      switch (true) {
        case capitalize(section.__typename) !== ContentTypeKeys.EventsSection:
        default:
          return section
        case section.type === EventsSectionType.UPCOMING_EVENTS:
          return {
            ...section,
            events: sortDatesBy({ arrayOfDates: events.upcoming, sortType: 'ASC' }),
          }
        case section.type === EventsSectionType.PAST_EVENTS:
          return { ...section, events: sortDatesByClosest(events.past) }
        case section.type === EventsSectionType.PRODUCT_CHATS:
          return { ...section, events: sortDatesByClosest(events.productChats) }
      }
    })
  }

  return (
    <>
      <Head data={data.seo} languageData={languageData} />
      <BreadcrumbJsonLd itemListElements={generateBreadCrumbs(router.query as NodeJS.Dict<string[]>)} />
      <LocalThemeProvider theme={{ pageTheme: getPageTheme(data.theme) }}>
        <PageLayout data={data.sectionList} />
      </LocalThemeProvider>
    </>
  )
}

interface PageProps {
  data: any | null //TODO: update the fetcher to not return <any>, and update any here
  languageData: ILanguageFields[] | null
  currentDomain: string
  isRedis: boolean
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  let getServerPropsCached: (pageURL: string) => Promise<string> = (() => null) as any
  let cacheServerProps: (pageURL: string, data: Record<string, any>) => void = () => null
  let setUrlById: (sysId: string, pageURL: string) => void = () => null

  if (!serverRuntimeConfig.useDrug) {
    const redisUtils = await import('@/utils/redis.utils')

    getServerPropsCached = redisUtils.getServerPropsCached
    cacheServerProps = redisUtils.cacheServerProps
    setUrlById = redisUtils.setUrlById
  }

  const { host } = absoluteUrl(ctx.req)
  const { publicRuntimeConfig } = getConfig()
  const currentDomain = host === DEVELOPMENT_HOST ? publicRuntimeConfig.webDomain : host
  const defaultLocale = DOMAIN_LOCALES[publicRuntimeConfig.webDomain]
  const currentLocale = DOMAIN_LOCALES[host] || defaultLocale
  const isPreviewMode = checkIsPreviewModeRequest(ctx.query)
  const pageSlug = getPageSlug(ctx.query.slug, ctx.req.url?.split('?')[0])
  const cachedPageSlug = pageSlug === '/' ? REDIS_HOMEPAGE_KEY : pageSlug
  const isRedis = false

  ctx.res.setHeader(
    'Cache-Control',
    'public, s-maxage=43200, stale-while-revalidate=60'
  )

  const basePageProps: PageProps = {
    data: null,
    languageData: null,
    currentDomain,
    isRedis
  }

  // TODO: check for !data in isErrorPage
  // Return error page if the URL belong to the error page
  const isErrorPage = checkIsErrorPage(pageSlug)
  if (isErrorPage) {
    return {
      props: {
        status: pageSlug.substring(1, 4),
        currentDomain,
        isRedis,
      },
    }
  }

  const serverPropsCached = JSON.parse(await getServerPropsCached(cachedPageSlug))
  if (serverPropsCached) {
    return {
      props: {
        ...(JSON.parse(JSON.stringify(serverPropsCached))),
        isRedis: true
      } 
    }
  }

  // TODO: check for slug isWebinarPage
  const isWebinarPage = checkIsEventPageApp(ctx.resolvedUrl)

  // Return full page if the URL doesn't belong to the error page
  if (isWebinarPage) {
    basePageProps.data = await getEventContent(
      isPreviewMode,
      pageSlug,
      DOMAIN_LOCALES[currentDomain]?.code
    )

    basePageProps.languageData = await getEventSlug(
      isPreviewMode,
      pageSlug,
      currentDomain,
      basePageProps.data?.sys?.id
    )
  } else {
    basePageProps.data = await getFullPageContent(
      isPreviewMode,
      pageSlug,
      DOMAIN_LOCALES[currentDomain]?.code
    )

    basePageProps.languageData = await getFullPageSlugs(
      isPreviewMode,
      pageSlug,
      currentDomain,
      basePageProps.data?.sys?.id
    )
  }

  const resourcePageType = checkIsResourcesPageApp(pageSlug.slice(1) || '')

  if (resourcePageType) {
    const resourceQueryType = resourcePageType !== EventType.RESOURCES ? resourcePageType : null
    const resourcesData = modifyResourcesData(await getEvents(isPreviewMode, DOMAIN_LOCALES[currentDomain]?.code, resourceQueryType))
    const pmArticles: any[] = await getPmArticles({
      isPreviewMode,
      locale: DOMAIN_LOCALES[currentDomain]?.code,
      pmArticleBooleanType: PmArticleBooleanType.showOnPpResource,
    })

    if (pmArticles.length) {
      resourcesData.reports?.push(...pmArticles)
    }

    const initialResourceListCount = resourcePageType === EventType.RESOURCES
      ? INITIAL_MAIN_RESOURCE_LIST_COUNT
      : INITIAL_SUB_RESOURCE_LIST_COUNT

    const sortAndChunkItemList = (originalList: IEvent[], isClosest = true): IEvent[] => {
      const sortFunction = isClosest
        ? sortDatesByClosest
        : (originalList: IEvent[]) => sortDatesBy({ arrayOfDates: originalList })

      return sortFunction(originalList)
        .slice(0, initialResourceListCount + LOAD_MORE_COUNT)
    }

    if (basePageProps.data?.sectionList) {
      const sortedResourcesData = {
        ...resourcesData,
        successStories: {
          items: sortAndChunkItemList(resourcesData.successStories),
          total: resourcesData.successStories.length,
        },
        ebooks: {
          items: sortAndChunkItemList(resourcesData.ebooks, false),
          total: resourcesData.ebooks.length,
        },
        reports: {
          items: sortAndChunkItemList(resourcesData.reports),
          total: resourcesData.reports.length,
        },
      }
      basePageProps.data.sectionList = resourcesPageSectionListModify(
        basePageProps.data.sectionList,
        {
          ...sortedResourcesData,
          locale: DOMAIN_LOCALES[currentDomain]?.code,
        },
      )
    }
  }

  if (basePageProps.data?.sectionList) {
    basePageProps.data.sectionList = await checkAndGetCarrierListingSectionData(
      basePageProps.data.sectionList,
      currentLocale.code,
      isPreviewMode
    )
  }

  // Handle fetching data for Content Feed Section
  const foundContentFeedSection = basePageProps.data?.sectionList?.find(
    (section: any) => capitalize(section.__typename) === ContentTypeKeys.ContentFeed
  )

  if (foundContentFeedSection?.tag?.type === CategoryTypeKeys.Tag) {
    // Get all latest articles belong to selected Tag
    const latestArticlesOfTag = (await getLatestTopicTagRelatedArticles({
      isPreviewMode,
      locale: DOMAIN_LOCALES[currentDomain]?.code,
      type: foundContentFeedSection?.tag?.type,
      pageSlug: foundContentFeedSection?.tag?.pageSlug,
    })) || []

    // If the selected `Category >> Tag` has fewer than 3 articles,
    // the latest articles across the entire Blog should be shown instead
    if (latestArticlesOfTag?.length < 3) {
      const lastestArticles = (await getThumbnails({
        isPreviewMode,
        locale: DOMAIN_LOCALES[currentDomain]?.code,
      })) || []
      const filteredLatestArticles = [
        ...latestArticlesOfTag, 
        ...lastestArticles.filter((article: ArticleProps) => 
          !latestArticlesOfTag?.find((articleOfTag: ArticleProps) => articleOfTag.sys.id === article.sys.id))
      ]
      foundContentFeedSection.contentFeed = filteredLatestArticles.slice(0, 3)
    } else {
      foundContentFeedSection.contentFeed = latestArticlesOfTag
    }
  }

  // Handle fetching data for Events Section
  const foundEventsSection = basePageProps.data?.sectionList?.find(
    (section: any) => capitalize(section.__typename) === ContentTypeKeys.EventsSection
  )

  if (foundEventsSection) {
    const events = await getEvents(isPreviewMode, DOMAIN_LOCALES[currentDomain]?.code)
    if (basePageProps?.data) basePageProps.data.events = events
  }

  // Needs page data to cache (languageData can be optional, but can keep it here just in case)
  if (!isPreviewMode && basePageProps.data && basePageProps.languageData?.length) {
    setUrlById(basePageProps.data.sys.id, cachedPageSlug)
    cacheServerProps(cachedPageSlug, basePageProps)
  }

  return {
    props: {
      ...JSON.parse(JSON.stringify(basePageProps))
    },
  }
}

export default FullPage
